import VueI18n from '../plugins/i18n';

export const categoriesComputedMixin = {
  computed: {
    electricalInstallationSubcategoriesList() {
      return Object.values(this.electricalInstallationSubcategoriesObj);
    },
    electricalInstallationSubcategoriesObj() {
      return {
        GROUNDED_CABLE: {
          value: 'GROUNDED_CABLE',
          text: VueI18n.t('statics.categoriesList.GROUNDED_CABLE'),
        },
        CABEL_DC: {
          value: 'CABEL_DC',
          text: VueI18n.t('statics.categoriesList.CABEL_DC'),
        },
        SWITCHGEAR: {
          value: 'SWITCHGEAR',
          text: VueI18n.t('statics.categoriesList.SWITCHGEAR'),
        },
        RESIDUAL: {
          value: 'RESIDUAL',
          text: VueI18n.t('statics.categoriesList.RESIDUAL'),
        },
        EARTHING: {
          value: 'EARTHING',
          text: VueI18n.t('statics.categoriesList.EARTHING'),
        },
        INSPECTION_BOX: {
          value: 'INSPECTION_BOX',
          text: VueI18n.t('statics.categoriesList.INSPECTION_BOX'),
        },
        CONDUIT: {
          value: 'CONDUIT',
          text: VueI18n.t('statics.categoriesList.CONDUIT'),
        },
        MC4: {
          value: 'MC4',
          text: VueI18n.t('statics.categoriesList.MC4'),
        },
        ELECTRICK_SHOCK: {
          value: 'ELECTRICK_SHOCK',
          text: VueI18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
        },
      };
    },
    categoriesList() {
      return Object.values(this.categoriesObj);
    },
    categoriesObj() {
      return {
        PHOTO_MODULES: {
          value: 'PHOTO_MODULES',
          text: VueI18n.t('statics.categoriesList.PHOTO_MODULES'),
        },
        INVERTERS: {
          value: 'INVERTERS',
          text: VueI18n.t('statics.categoriesList.INVERTERS'),
        },
        OPTIMIZERS: {
          value: 'OPTIMIZERS',
          text: VueI18n.t('statics.categoriesList.OPTIMIZERS'),
        },
        MOUNTING_ACCESSORIES: {
          value: 'MOUNTING_ACCESSORIES',
          text: VueI18n.t('statics.categoriesList.MOUNTING_ACCESSORIES'),
        },
        GROUNDED_CABLE: {
          value: 'GROUNDED_CABLE',
          text: VueI18n.t('statics.categoriesList.GROUNDED_CABLE'),
        },
        CABEL_DC: {
          value: 'CABEL_DC',
          text: VueI18n.t('statics.categoriesList.CABEL_DC'),
        },
        SWITCHGEAR: {
          value: 'SWITCHGEAR',
          text: VueI18n.t('statics.categoriesList.SWITCHGEAR'),
        },
        RESIDUAL: {
          value: 'RESIDUAL',
          text: VueI18n.t('statics.categoriesList.RESIDUAL'),
        },
        EARTHING: {
          value: 'EARTHING',
          text: VueI18n.t('statics.categoriesList.EARTHING'),
        },
        INSPECTION_BOX: {
          value: 'INSPECTION_BOX',
          text: VueI18n.t('statics.categoriesList.INSPECTION_BOX'),
        },
        CONDUIT: {
          value: 'CONDUIT',
          text: VueI18n.t('statics.categoriesList.CONDUIT'),
        },
        MC4: {
          value: 'MC4',
          text: VueI18n.t('statics.categoriesList.MC4'),
        },
        ELECTRICK_SHOCK: {
          value: 'ELECTRICK_SHOCK',
          text: VueI18n.t('statics.categoriesList.ELECTRICK_SHOCK'),
        },
        OTHERS: {
          value: 'OTHERS',
          text: VueI18n.t('statics.categoriesList.OTHERS'),
        },
        PRODUCTS_SET: {
          value: 'PRODUCTS_SET',
          text: VueI18n.t('statics.categoriesList.PRODUCTS_SET'),
        },
        ENERGY_STORAGE: {
          value: 'ENERGY_STORAGE',
          text: VueI18n.t('statics.categoriesList.ENERGY_STORAGE'),
        },
        HEAT_PUMPS: {
          value: 'HEAT_PUMPS',
          text: VueI18n.t('statics.categoriesList.HEAT_PUMPS'),
        },
      };
    },
    subCategoriesList() {
      return Object.values(this.subCategoriesObj);
    },
    subCategoriesObj() {
      return {
        CONTROLE_MODULE: {
          value: 'CONTROLE_MODULE',
          text: VueI18n.t('statics.subCategoriesList.CONTROLE_MODULE'),
        },
        BATTERY: {
          value: 'BATTERY',
          text: VueI18n.t('statics.subCategoriesList.BATTERY'),
        },
      };
    },
    energyStorageSelectOptions() {
      return [{ value: '', text: VueI18n.t('helpers.allProducts') }, ...this.subCategoriesList];
    },
  },
};
