<template>
  <div>
    <h1 data-test="header">{{ $t('components.priceLists.header') }}</h1>
    <div v-if="isButtonVisible">
      <v-btn
        elevation="0"
        block
        @click="addNewPriceListForProduct"
        :color="'primary'"
        class="pa-4 v-size--large white--text"
        x-large
        data-test="add-new-price-list-button"
        >{{ $t('components.priceLists.addNewPriceList') }}</v-btn
      >
    </div>
    <price-list-item
      @priceListDeleted="removePriceList($event)"
      @updatePriceListGroupQuantities="updatePriceListGroupQuantities"
      v-for="(item, index) in priceLists"
      :key="item._id || index"
      :priceList="getPriceListItem(item)"
      @updatePrice="updatePrice"
      class="mt-6"
    ></price-list-item>
    <add-product-to-price-list-modal
      :open="isModalOpen"
      @close="closeModal"
      @confirm="confirmAndAddNewPriceList"
      :priceLists="getAvaiablePriceLists"
    ></add-product-to-price-list-modal>
  </div>
</template>

<script>
import PriceListService from '../../services/PriceListService';
import PriceListItem from './PriceListItem.vue';
import AddProductToPriceListModal from '../modals/AddProductToPriceListModal.vue';

export default {
  components: { PriceListItem, AddProductToPriceListModal },
  emits: ['addNewPriceList', 'priceListDeleted'],
  props: {
    priceLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      totalPriceLists: [],
      isModalOpen: false,
      allPriceLists: [],
    };
  },
  computed: {
    priceListsComputed() {
      return this.priceLists;
    },

    totalPriceListsQuantity() {
      return this.totalPriceLists.length;
    },
    isButtonVisible() {
      return this.priceLists.length === 0 || this.priceLists.length < this.totalPriceListsQuantity;
    },
    getAvaiablePriceLists() {
      const existingIds = new Set(this.priceLists.map((priceList) => priceList.priceListId));
      return this.totalPriceLists.filter((priceList) => !existingIds.has(priceList._id));
    },
  },
  methods: {
    updatePrice(priceListId, price) {
      this.priceLists = this.priceLists.map((priceList) => {
        if (priceList.priceListId === priceListId) {
          priceList.price = price;
        }
        return priceList;
      });
    },
    getPriceListItem(item) {
      return {
        ...item,
        name:
          this.totalPriceLists.find((priceList) => priceList._id === item.priceListId)?.name ||
          item.name,
      };
    },
    addNewPriceListForProduct() {
      this.isModalOpen = true;
    },
    async confirmAndAddNewPriceList(selectedPriceList) {
      this.$emit('addNewPriceList', selectedPriceList);
      this.isModalOpen = false;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    removePriceList(priceListId) {
      this.$emit('priceListDeleted', priceListId);
    },
    updatePriceListGroupQuantities(priceListId, groupQuantities) {
      this.$emit('updatePriceListGroupQuantities', priceListId, groupQuantities);
    },
  },

  async mounted() {
    try {
      const res = await PriceListService.getAllPriceLists();
      this.totalPriceLists = res.data;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>

<style></style>
