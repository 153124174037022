class Optimizer {
  constructor(power, amps) {
    this.attributes = [
      {
        name: 'Moc',
        unit: 'W',
        value: amps || '',
      },
      {
        name: 'Amperaż',
        unit: 'a',
        value: power || '',
      },
    ];
    this.categoryName = 'Optymalizator';
  }
}

module.exports = Optimizer;
