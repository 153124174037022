class CableDC {
  constructor(crossSection) {
    this.attributes = [
      {
        name: 'Przekrój',
        unit: 'mm2',
        value: crossSection || '',
      },
    ];
    this.categoryName = 'Kabel DC';
  }
}

module.exports = CableDC;
