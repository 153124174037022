import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/price-list`;

axios.defaults.withCredentials = true;

class PriceListService {
  static async getPriceListsForProduct(productId) {
    const res = await axios.get(`${url}/${productId}`);
    return res.data;
  }

  static async updatePriceList(priceListId, productId, price) {
    const res = await axios.put(`${url}/${priceListId}/products`, { productId, price });

    return res.data;
  }

  static async getAllPriceLists() {
    const res = await axios.get(`${url}`);

    return res.data;
  }

  static async deletePriceList(priceListId, productId) {
    const res = await axios.patch(`${url}/${priceListId}/products/${productId}`);

    return res.data;
  }
}

export default PriceListService;
