<template>
  <v-dialog
    transition="dialog-bottom-transition"
    class="rounded-0"
    v-model="open"
    @click:outside="close"
    persistend
    max-width="600"
  >
    <v-card class="text-center pt-4 pb-6 px-8" tile>
      <div class="text-right">
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <h2 class="card-title primary--text">
        {{ $t('components.priceLists.selectAvaiablePriceLists') }}
      </h2>
      <div class="text-center mt-4">
        <v-select
          v-model="selectedPriceList"
          :items="avaiablePriceLists"
          :label="$t('components.priceLists.header')"
          item-text="name"
          return-object
          outlined
          solo
        ></v-select>
      </div>
      <primary-button class="mt-4" color="primary" type="button" :onClick="confirm">
        {{ $t('components.priceLists.addNewPriceList') }}
      </primary-button>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '../buttons/PrimaryButton.vue';

export default {
  name: 'StatementModal',
  components: { PrimaryButton },
  emits: ['close', 'confirm', 'addNewPriceList'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    priceLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPriceList: {},
    };
  },
  computed: {
    avaiablePriceLists() {
      return this.priceLists;
    },
  },
  methods: {
    confirm() {
      if (!this.selectedPriceList.name) {
        this.$toasted.global.error({
          message: this.$t('components.priceLists.priceListNotSelected'),
        });
        return;
      }
      this.$emit('confirm', this.selectedPriceList);
      this.selectedPriceList = {};
    },
    close() {
      this.$emit('close');
      this.selectedPriceList = {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
