<template>
  <div>
    <v-row class="white px-4 py-2 mb-3">
      <v-col cols="12" sm="3" class="tertiary-text--text"
        >{{ $t('components.discountPrices.title') }}
      </v-col>
      <v-col
        cols="12"
        sm="3"
        class="tertiary-text--text"
        v-for="(groupQuantity, index) in discountPricesQuanities"
        :key="groupQuantity.label"
      >
        {{ getDiscountPriceRangeName(groupQuantity.label) }}
        <span v-html="groupRange(groupQuantity.min, groupQuantity.max)"> </span>
        <v-btn
          icon
          v-if="index + 1 === discountPricesQuanities.length"
          class="float-right pa-0"
          height="auto"
          width="auto"
          @click="openChangeQuantityModal"
          ><v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-4 py-0 my-0" v-for="discount in discountPrices" :key="discount.name">
      <v-col cols="12" sm="3" class="tertiary-text--text py-7">
        {{ $t(`statics.discountGroups.${discount.name}`) }}</v-col
      >
      <v-col cols="12" sm="3" class="tertiary-text--text">
        <v-text-field
          class="mt-1"
          min="0"
          type="number"
          :rules="validator.priceGroupRules"
          hide-details="auto"
          solo
          v-model.number="discount.price.normal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" class="tertiary-text--text">
        <v-text-field
          class="mt-1"
          min="0"
          type="number"
          :rules="validator.priceGroupRules"
          hide-details="auto"
          solo
          v-model.number="discount.price.palette"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" class="tertiary-text--text">
        <v-text-field
          class="mt-1"
          :rules="validator.priceGroupRules"
          hide-details="auto"
          min="0"
          type="number"
          solo
          v-model.number="discount.price.container"
        ></v-text-field>
      </v-col>
    </v-row>
    <confirm-modal
      @decline="cancelQuantityChange"
      @accept="updateQuantities"
      :title="$t('components.discountPrices.modal.title')"
      :open="quantityChangeModal"
      :agree="$t('components.discountPrices.modal.agree')"
    >
      <v-form v-model="groupQuantitiesFormValid" ref="groupQuantitiesForm">
        <div v-for="groupQuantity in groupQuantities" :key="groupQuantity.label">
          <v-row>
            <v-col class="pa-1">
              <h2 class="text-overline">
                {{ $t(`statics.discountPrice.${groupQuantity.name.toString().toUpperCase()}`) }}
              </h2>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row class="mb-3">
            <v-col class="pa-1" cols="6">
              <h3 class="text mb-3 success--text">MIN</h3>
              <v-text-field
                v-model.number="groupQuantity.min"
                :rules="validator.discountGroupQuantityRules"
                hide-details="auto"
                outlined
                solo
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6" v-if="!isContainerGroup(groupQuantity.label)">
              <h3 class="text mb-3 primary--text">MAX</h3>
              <v-text-field
                v-model.number="groupQuantity.max"
                :rules="validator.discountGroupQuantityRules"
                hide-details="auto"
                outlined
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </confirm-modal>
  </div>
</template>
<script>
import { validators } from '../validators/Validators';
import ConfirmModal from './modals/ConfirmModal.vue';
import { some, cloneDeep, find, isEqual } from 'lodash';
import discountPrices from '../static/discountPrices';
import VueI18n from '../plugins/i18n';
import { discountGroupsPath } from '../static/discountGroupsPath';
import { discountPricesTranslated } from '../static/discountPricesTranslated';

export default {
  name: 'DiscountPrices',
  components: { ConfirmModal },
  props: ['discountPrices', 'discountPricesQuanities'],
  data() {
    return {
      quantityChangeModal: false,
      groupQuantitiesFormValid: false,
      groupQuantities: cloneDeep(this.discountPricesQuanities),
    };
  },
  methods: {
    getDiscountGroupPath(group) {
      return Object.values(discountGroupsPath).find(
        (discountGroup) => discountGroup.value === group
      )?.path;
    },
    getDiscountPriceRangeName(rangeName) {
      return Object.values(discountPricesTranslated).find((item) => item.value === rangeName)?.name;
    },
    openChangeQuantityModal() {
      this.quantityChangeModal = true;
    },
    cancelQuantityChange() {
      this.quantityChangeModal = false;
      this.groupQuantities = cloneDeep(this.discountPricesQuanities);
    },
    updateQuantities() {
      this.$refs.groupQuantitiesForm.validate();
      if (!this.groupQuantitiesFormValid) return this.groupQuantities;
      this.validateRanges();
    },
    groupRange(min, max) {
      return max && max !== Number.MAX_SAFE_INTEGER ? `(${min} - ${max})` : `(&gt;${min})`;
    },
    rangePredicate({ min, max }) {
      if (max) {
        return min > max;
      }
    },
    validateRanges() {
      if (!this.validateGroupRanges())
        return this.$toasted.global.error({
          message: VueI18n.t('components.discountPrices.toasted.errorMin'),
        });
      const normalGroup = find(this.groupQuantities, { label: discountPrices.NORMAL });
      const paletteGroup = find(this.groupQuantities, { label: discountPrices.PALETTE });
      const containerGroup = find(this.groupQuantities, { label: discountPrices.CONTAINER });
      if (normalGroup.max >= paletteGroup.min || paletteGroup.max >= containerGroup.min)
        return this.$toasted.global.error({
          message: VueI18n.t('components.discountPrices.toasted.errorMax'),
        });
      this.$emit('updateGroupQuantities', this.groupQuantities);
      this.quantityChangeModal = false;
    },
    validateGroupRanges() {
      const generalRangeValid = !some(this.groupQuantities, this.rangePredicate);
      return generalRangeValid;
    },
    isContainerGroup(label) {
      return isEqual(label, discountPrices.CONTAINER);
    },
  },
  computed: {
    validator() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-btn__content {
  padding: 0 !important;
}
</style>
