import VueI18n from '../plugins/i18n';

export const accessoryCategories = Object.freeze({
  TRIANGLE: 'Trójkąt',
  RAIL220: 'Szyna 220',
  RAIL250: 'Szyna 250',
  RAIL300: 'Szyna 300',
  DOUBLESCREW: 'Podwójna śruba',
  HANDLE: 'Uchwyt',
  OTHER: 'Inne akcesoria',
  END_CLAMP: 'Klema końcowa',
  IMBUS_SCREW: 'Śruba imbusowa',
});

export const accessoryCategoriesList = Object.freeze([
  {
    value: 'Trójkąt',
    text: VueI18n.t('statics.accessoryCategoriesList.TRIANGLE'),
  },
  {
    value: 'Szyna 220',
    text: VueI18n.t('statics.accessoryCategoriesList.RAIL220'),
  },
  {
    value: 'Szyna 250',
    text: VueI18n.t('statics.accessoryCategoriesList.RAIL250'),
  },
  { value: 'Szyna 300', text: VueI18n.t('statics.accessoryCategoriesList.RAIL300') },
  {
    value: 'Podwójna śruba',
    text: VueI18n.t('statics.accessoryCategoriesList.DOUBLESCREW'),
  },
  {
    value: 'Uchwyt',
    text: VueI18n.t('statics.accessoryCategoriesList.HANDLE'),
  },
  {
    value: 'Inne akcesoria',
    text: VueI18n.t('statics.accessoryCategoriesList.OTHER'),
  },
  {
    value: 'Klema końcowa',
    text: VueI18n.t('statics.accessoryCategoriesList.END_CLAMP'),
  },
  {
    value: 'Śruba imbusowa',
    text: VueI18n.t('statics.accessoryCategoriesList.IMBUS_SCREW'),
  },
]);
