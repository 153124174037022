<template>
  <v-row>
    <v-col class="d-flex flex-row flex-wrap">
      <div v-for="(file, index) in files" class="mr-4 mb-4" :key="index">
        <downloadable-link :link="file.path"
          ><v-icon class="mr-2">mdi-download</v-icon>{{ file.name }} [{{
            getLanguageText(file.lang)
          }}]
        </downloadable-link>
        <v-icon class="ml-2" @click="() => deleteFile(file._id)">mdi-delete</v-icon>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { supportedLangs } from '../static/supportedLangs';
import DownloadableLink from './links/DownloadableLink.vue';

export default {
  name: 'AdditionalFiles',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  components: { DownloadableLink },
  methods: {
    deleteFile(fileId) {
      this.$emit('deleteFile', fileId);
    },
    getLanguageText(lang) {
      return supportedLangs[lang].textForUser;
    },
  },
};
</script>
<style lang="scss" scoped></style>
