<template>
  <v-container>
    <v-form v-model="productFormValid" ref="form">
      <v-row
        ><v-col
          ><h1 class="title">{{ $t('components.singleProduct.title') }}</h1></v-col
        ></v-row
      >
      <v-row
        ><v-col cols="12" sm="3"
          ><v-container
            ><v-row>
              <v-col class="white d-flex align-center justify-center"
                ><v-img
                  contain
                  v-if="product.image"
                  :lazy-src="product.image"
                  height="200"
                  width="auto"
                  :src="product.image"
                ></v-img>
                <v-img v-else height="200" width="auto">
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-icon size="80px">mdi-image</v-icon>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
            <v-row class="py-0 mb-0 mt-5">
              <v-col
                ><v-file-input
                  v-model="inputImage"
                  :clearable="false"
                  hide-details="auto"
                  persistent-hint
                  :hint="$t('components.singleProduct.fileInputImage.hint')"
                  accept="image/jpeg, image/png, image/webp, image/svg+xml"
                  prepend-icon="mdi-camera"
                  solo
                >
                  <template v-slot:selection="{ text }">
                    {{ text ? text : $t('components.singleProduct.fileInputImage.text') }}
                  </template>
                </v-file-input>
              </v-col></v-row
            >
            <v-row class="py-0 mt-0"
              ><v-col class="pt-0 text-center"
                ><span class="text-decoration-underline pointer" @click="setImage">{{
                  $t('components.singleProduct.upload')
                }}</span></v-col
              ><v-col class="pt-0 text-center"
                ><span class="text-decoration-underline pointer" @click="deleteImage">{{
                  $t('helpers.remove')
                }}</span></v-col
              ></v-row
            >
            <v-row class="mt-6">
              <v-col class="font-weight-bold pl-0"
                ><v-icon class="ml-3 mr-2 mb-1">$pdfIcon</v-icon
                >{{ $t('components.singleProduct.productCard') }}
                <v-btn
                  v-if="pdfSpecification"
                  tag="a"
                  :href="pdfSpecification"
                  download
                  class="ml-1"
                  target="_blank"
                  icon
                  ><v-icon>mdi-download</v-icon></v-btn
                ></v-col
              ></v-row
            >
            <v-row class="my-0 py-0">
              <v-col>
                <v-file-input
                  v-model="inputPdf"
                  hide-details="auto"
                  accept="application/pdf"
                  :hint="$t('components.singleProduct.fileInputPdf.hint')"
                  persistent-hint
                  :clearable="false"
                  prepend-icon="mdi-file"
                  solo
                >
                  <template v-slot:selection="{ text }">
                    {{ pdfInputText(text) }}
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row class="py-0 mt-0"
              ><v-col class="pt-0 text-center"
                ><span class="text-decoration-underline pointer" @click="setPdf">{{
                  $t('components.singleProduct.upload')
                }}</span></v-col
              ><v-col class="pt-0 text-center"
                ><span class="text-decoration-underline pointer" @click="deletePdf">{{
                  $t('helpers.remove')
                }}</span></v-col
              ></v-row
            >
            <v-row>
              <v-col>
                <v-select
                  v-model="lang"
                  :items="langs"
                  @change="onLangChange"
                  solo
                  hide-details="true"
                  :label="lang"
                ></v-select>
              </v-col>
            </v-row> </v-container
        ></v-col>
        <v-col cols="12" sm="9"
          ><v-container
            ><v-row
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('helpers.category') }}</h2>
                <v-select
                  v-model="product.category"
                  :items="categoriesList"
                  elevation="0"
                  @change="setAttributes"
                  :label="$t('helpers.category')"
                  solo
                  hide-details
                ></v-select
              ></v-col>
              <v-col cols="12" sm="6">
                <h2 class="text mb-2">
                  {{ $t('components.singleProduct.productCode.partOne') }} (<span
                    class="red--text"
                    >{{ $t('components.singleProduct.productCode.partTwo') }}</span
                  >)
                </h2>
                <v-text-field
                  v-model="product.code"
                  elevation="0"
                  disabled
                  :label="$t('components.singleProduct.productCodeLabel')"
                  solo
                  hide-details
                ></v-text-field></v-col></v-row
            ><v-row v-if="product.category === categoriesObj.ENERGY_STORAGE.value">
              <v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('components.singleProduct.subCategory') }}</h2>
                <v-select
                  v-model="product.subCategory"
                  :items="subCategoriesList"
                  elevation="0"
                  @change="setSubCategoryAttributes"
                  :label="$t('components.singleProduct.subCategory')"
                  solo
                  hide-details
                ></v-select
              ></v-col>
            </v-row>
            <v-row
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('helpers.producer') }}</h2>
                <v-select
                  v-model="product.producer"
                  :items="producersList"
                  elevation="0"
                  :label="$t('helpers.producer')"
                  solo
                  hide-details
                ></v-select></v-col
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('helpers.filterName') }}</h2>
                <v-text-field
                  v-model="langEditables.name"
                  elevation="0"
                  :label="$t('helpers.filterName')"
                  solo
                  hide-details
                  @change="productLangsHasBeenEdited"
                ></v-text-field></v-col></v-row
            ><v-row
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('helpers.visibility') }}</h2>
                <v-select
                  v-model="visible"
                  :items="visibilityOptions"
                  elevation="0"
                  :label="$t('helpers.visibility')"
                  solo
                  hide-details
                ></v-select></v-col
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('helpers.stock') }}</h2>
                <v-text-field
                  v-model="product.supply"
                  elevation="0"
                  :label="$t('helpers.stock')"
                  solo
                  hide-details
                ></v-text-field></v-col
            ></v-row>
            <v-row
              ><v-col v-if="isAccessoryCatVisible" cols="12" sm="6">
                <h2 class="text mb-2">{{ $t('components.singleProduct.accessorySubCategory') }}</h2>
                <v-select
                  v-model="accessoryCategory"
                  :items="accessoryCategoriesList"
                  elevation="0"
                  :label="$t('components.singleProduct.subCategory')"
                  solo
                  hide-details
                ></v-select></v-col
              ><v-col cols="12" sm="6">
                <h2 class="text mb-2">
                  {{ $t('components.singleProduct.incrementWhileAddingToCart') }}
                </h2>
                <v-text-field
                  v-model="product.incrementWhileAddingToCart"
                  elevation="0"
                  :label="$t('components.singleProduct.incrementWhileAddingToCart')"
                  solo
                  hide-details
                ></v-text-field></v-col
            ></v-row>
            <v-row
              ><v-col cols="12" sm="12">
                <h2 class="text">
                  {{ $t('components.singleProduct.shortDescriptionInput.title') }}
                </h2>
                <v-textarea
                  v-model="langEditables.shortDescription"
                  solo
                  :hint="$t('components.singleProduct.shortDescriptionInput.hint')"
                  persistent-hint
                  :rules="validator.shortDescRules"
                  rows="3"
                  :no-resize="true"
                  @change="productLangsHasBeenEdited"
                ></v-textarea> </v-col
            ></v-row>
            <v-row
              ><v-col
                ><h2 class="text">
                  {{ $t('components.singleProduct.longDescriptionInput.title') }}
                </h2>
                <v-textarea
                  v-model="langEditables.longDescription"
                  :no-resize="true"
                  rows="6"
                  :hint="$t('components.singleProduct.longDescriptionInput.hint')"
                  persistent-hint
                  :rules="validator.longDescRules"
                  solo
                  @change="productLangsHasBeenEdited"
                ></v-textarea></v-col></v-row></v-container></v-col
      ></v-row>
      <div v-if="isTechnicalVisible()">
        <v-row>
          <v-col
            ><h2 class="title">{{ $t('components.singleProduct.technicalData') }}</h2></v-col
          >
        </v-row>
        <v-row>
          <v-col
            v-for="attribute in product.attributes"
            :key="attribute.name"
            cols="12"
            sm="4"
            class="mb-0"
          >
            <h2 class="text mb-2">
              {{ $t(`statics.attributes.${getAttributePath(attribute)}`) }}
              <span v-if="attribute.unit"> [{{ getUnitTranslation(attribute.unit) }}]</span>
            </h2>
            <v-text-field
              v-if="isAttributePowerAndCategoryProductsSet(attribute)"
              solo
              v-model="attribute.value"
              :rules="validator.productsSetPowerRules"
              :hint="setHintForProductsSet(attribute.value)"
              persistent-hint
            >
            </v-text-field>
            <v-text-field
              solo
              v-if="
                attribute.name !== 'Czy fullblack' &&
                attribute.name !== 'Kolor ramy' &&
                attribute.name !== 'Grubość modułu' &&
                attribute.name !== 'Długość kabla' &&
                attribute.name !== 'Waga' &&
                !isAttributePowerAndCategoryProductsSet(attribute)
              "
              v-model="attribute.value"
              :item-text="$t(`statics.attributes.${getAttributePath(attribute)}`)"
              :rules="attribute.name !== 'Kolor' && validator.attributeRules"
            >
            </v-text-field>
            <v-select
              solo
              v-if="attribute.name === 'Czy fullblack'"
              v-model="attribute.value"
              :items="fullBlackValues"
            >
            </v-select>
            <v-select
              solo
              v-if="attribute.name === 'Kolor ramy'"
              v-model="attribute.value"
              :items="frameColorValues"
            >
            </v-select>
            <v-select
              solo
              v-if="attribute.name === 'Grubość modułu'"
              v-model="attribute.value"
              :items="attribute.options"
            >
            </v-select>
            <v-text-field
              class="mt-1"
              min="0"
              v-if="attribute.name === 'Długość kabla'"
              type="number"
              :rules="validator.priceGroupRules"
              hide-details="auto"
              solo
              v-model="attribute.value"
            ></v-text-field>
            <v-text-field
              class="mt-1"
              min="0"
              v-if="attribute.name === 'Waga'"
              type="number"
              :rules="validator.priceGroupRules"
              hide-details="auto"
              solo
              v-model="attribute.value"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="isCategoryProductsSet">
        <v-col cols="12" sm="4">
          <h2 class="text mb-2">
            {{
              $t('components.singleProduct.productSetPrice', {
                currency: supportedCurrencies.PLN.value,
              })
            }}
          </h2>
          <v-text-field
            v-model="product.setDeliveryPrice"
            elevation="0"
            solo
            type="number"
            :rules="validator.priceGroupRules"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ><h2 class="title">{{ $t('components.singleProduct.additionalFiles') }}</h2></v-col
        >
      </v-row>
      <additional-files :files="product.files" v-on:deleteFile="deleteFile"></additional-files>
      <v-row>
        <v-col>
          <OutlinedButton :onClick="toogleAddProductFileModal">{{
            $t('components.singleProduct.addAdditionalFiles')
          }}</OutlinedButton>
        </v-col>
      </v-row>

      <v-row class="mt-8">
        <v-col
          ><h1 class="title">{{ $t('components.singleProduct.priceList') }}</h1></v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="my-auto">
          <v-alert outlined type="success" class="my-auto enova-alert">
            {{ $t('components.singleProduct.infoEnova') }}:
          </v-alert>
        </v-col>
        <v-col cols="12" sm="3">
          {{ $t('components.singleProduct.availableAmount') }}
          <v-text-field class="mt-1" solo :value="product.stock" disabled></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          {{ $t('components.singleProduct.standardPrice') }}
          <v-text-field class="mt-1" solo :value="product.standardPrice" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" offset="9">
          {{ $t('components.singleProduct.priceFromEnova') }}
          <v-text-field class="mt-1" solo :value="product.priceFromEnova" disabled></v-text-field>
        </v-col>
      </v-row>
      <discount-prices
        v-if="product.discountPricesQuanities"
        :discountPrices="product.discountPrices"
        :discountPricesQuanities="product.discountPricesQuanities"
        @updateGroupQuantities="updateGroupQuantities"
        ref="discountPrices"
      ></discount-prices>
      <price-lists
        :priceLists="product.priceLists"
        @addNewPriceList="addNewPriceList"
        @priceListDeleted="removePriceList"
        @updatePriceListGroupQuantities="updatePriceListGroupQuantities"
      ></price-lists>
      <v-row class="mt-4 px-0">
        <v-col class="text-right">
          <primary-button :onClick="updateProduct" :loading="isUpdating">{{
            $t('helpers.saveChanges')
          }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
    <AddProductFileModal
      :open="productFileModal.open"
      @decline="toogleAddProductFileModal"
      @accept="addProductFile"
    ></AddProductFileModal>
  </v-container>
</template>
<script>
import PrimaryButton from '../../../../components/buttons/PrimaryButton.vue';
import DiscountPrices from '../../../../components/DiscountPrices.vue';
import {
  accessoryCategories,
  accessoryCategoriesList,
} from '../../../../static/accessoryCategoriesList';
import discountGroups from '../../../../static/discountGroups';
import ProductService from '../../../../services/ProductService';
import CableDC from '../../../../static/categories/CableDC';
import GroundWire from '../../../../static/categories/GroundWire';
import Inverter from '../../../../static/categories/Inverter';
import Optimizers from '../../../../static/categories/Optimizers';
import EnergyStorageBattery from '../../../../static/categories/EnergyStorageBattery';
import EnergyStorageControleModule from '../../../../static/categories/EnergyStorageControleModule';
import PhotovoltaicModules from '../../../../static/categories/PhotovoltaicModules';
import ProductsSet from '../../../../static/categories/ProductsSet';
import { validators } from '../../../../validators/Validators';
import { isEmpty, cloneDeep } from 'lodash';
import ProducerService from '../../../../services/ProducerService';
import PriceListService from '../../../../services/PriceListService';
import VueI18n, { supportedLanguages } from '../../../../plugins/i18n';
import { categoriesComputedMixin } from '../../../../mixins/categoriesComputedMixin';
import defaultLang from '../../../../static/defaultLang';
import { visibilityOptions } from '../../../../static/visibilityOptions';
import { allAttributes } from '../../../../static/categories/allAttributes';
import { supportedCurrencies } from '../../../../static/supportedCurrencies';
import { getNameByLanguage } from '../../../../mixins/getNameByLanguage';
import { attributesNameMixin } from '../../../../mixins/attributesNameMixin';
import AdditionalFiles from '../../../../components/AdditionalFiles.vue';
import OutlinedButton from '../../../../components/buttons/OutlinedButton.vue';
import AddProductFileModal from '../../../../components/modals/AddProductFileModal.vue';
import PriceLists from '../../../../components/PriceLists/PriceLists.vue';
import HeatPump from '../../../../static/categories/HeatPump';

export default {
  components: {
    PrimaryButton,
    DiscountPrices,
    AdditionalFiles,
    OutlinedButton,
    AddProductFileModal,
    PriceLists,
  },
  name: 'SingleProduct',
  mixins: [categoriesComputedMixin, getNameByLanguage, attributesNameMixin],
  data() {
    return {
      priceLists: [],
      productFileModal: {
        open: false,
      },
      pdfSpecification: '',
      productId: '',
      productLangsEdited: false,
      lang: defaultLang.PL,
      currentLang: defaultLang.PL,
      langEditables: {
        code: defaultLang.PL,
        name: '',
        shortDescription: '',
        longDescription: '',
      },
      productFormValid: false,
      menu1: false,
      menu2: false,
      product: {},
      accessoryCategory: accessoryCategories.OTHER,
      isUpdating: false,
      inputPdf: {},
      incrementWhileAddingToCart: 1,
      inputImage: {},
      producers: [],
      visible: false,
      defaultDiscountPrices: [
        {
          name: discountGroups.BASIC,
          price: {
            normal: 0,
            palette: 0,
            container: 0,
          },
        },
        {
          name: discountGroups.STANDARD,
          price: {
            normal: 0,
            palette: 0,
            container: 0,
          },
        },
        {
          name: discountGroups.PREMIUM,
          price: {
            normal: 0,
            palette: 0,
            container: 0,
          },
        },
        {
          name: discountGroups.VIP,
          price: {
            normal: 0,
            palette: 0,
            container: 0,
          },
        },
      ],
      supportedCurrencies,
    };
  },
  computed: {
    getFileName() {
      if (!this.pdfSpecification?.length) return '';
      let longName;
      if (!this.pdfSpecification?.includes('@')) {
        longName = this.pdfSpecification;
      } else {
        const splitetFilePath = this.pdfSpecification.split('@');
        longName = splitetFilePath[1];
      }
      const cropToLength = 15;

      if (longName?.length > cropToLength) {
        var start = longName.substring(0, 5);
        var end = longName.substring(longName.length - 7, longName.length);
        while (start.length + end.length < cropToLength) {
          start = start + '...';
        }
        return start + end;
      }
      return longName;
    },
    fullBlackValues() {
      return [
        { value: 'Tak', text: this.$i18n.t('components.singleProduct.fullBlackValues.yes') },
        { value: 'Nie', text: this.$i18n.t('components.singleProduct.fullBlackValues.no') },
      ];
    },
    frameColorValues() {
      return [
        { value: 'Srebrny', text: this.$i18n.t('components.singleProduct.frameColor.silver') },
        { value: 'Czarny', text: this.$i18n.t('components.singleProduct.frameColor.black') },
      ];
    },
    visibilityOptions() {
      return visibilityOptions();
    },
    langs() {
      return supportedLanguages;
    },
    isAccessoryCatVisible() {
      return this.isAccessories();
    },
    accessoryCategories() {
      return Object.values(accessoryCategories);
    },
    accessoryCategoriesList() {
      return accessoryCategoriesList;
    },
    validator() {
      return validators;
    },
    producersList() {
      return this.producers.map((el) => el.name).sort();
    },

    emptyImage() {
      return isEmpty(this.inputImage?.name);
    },
    emptyPdf() {
      return isEmpty(this.inputPdf?.name);
    },
    isCategoryProductsSet() {
      return this.product.category === this.categoriesObj.PRODUCTS_SET.value;
    },
    isProductsSet() {
      return this.product.category === this.categoriesObj.PRODUCTS_SET.value;
    },
  },
  methods: {
    updatePriceListGroupQuantities(priceListId, groupQuantities) {
      this.product.priceLists = this.product.priceLists.map((priceList) => {
        if (priceList.priceListId === priceListId) {
          priceList.discountPricesQuanities = cloneDeep(groupQuantities);
        }
        return priceList;
      });
    },
    removePriceList(priceListId) {
      this.product.priceLists = this.product.priceLists.filter(
        (priceList) => priceList.priceListId !== priceListId
      );
    },
    async addNewPriceList(priceList) {
      const newPriceList = { priceListId: priceList._id, productId: this.productId, price: 0.0 };

      this.product.priceLists.push(newPriceList);
      await this.updateProduct();
    },
    async getProductPriceLists(productId) {
      const priceLists = await PriceListService.getPriceListsForProduct(productId);

      return priceLists.data;
    },
    async addProductFile(requestBody) {
      try {
        this.toogleAddProductFileModal();
        await ProductService.addFile(this.productId, requestBody);
        await this.getProduct(this.productId);
      } catch (err) {
        console.error(err);
      }
    },
    toogleAddProductFileModal() {
      this.productFileModal.open = !this.productFileModal.open;
    },
    async deleteFile(fileId) {
      try {
        await ProductService.deleteFile(this.productId, fileId);
        await this.getProduct(this.productId);
      } catch (err) {
        console.error(err);
      }
    },
    setHintForProductsSet(value) {
      return this.isCategoryProductsSet && value.length === 0
        ? this.$i18n.t('helpers.required')
        : '';
    },
    pdfInputText(text) {
      return text
        ? text
        : this.pdfSpecification
        ? this.getFileName
        : this.$i18n.t('components.singleProduct.fileInputPdf.text');
    },
    productLangsHasBeenEdited() {
      this.productLangsEdited = true;
    },
    isAttributePowerAndCategoryProductsSet(attribute) {
      return attribute.name === 'Moc' && this.isProductsSet;
    },
    getAttributePath(attr) {
      return Object.values(allAttributes).find((item) => item.value === attr.name)?.text;
    },
    isDefaultLang(langToCompare) {
      return langToCompare === defaultLang.PL;
    },
    setDefaultLang() {
      this.lang = defaultLang.PL;
      this.langEditables.code = defaultLang.PL;
      this.langEditables.name = this.product.name;
      this.langEditables.shortDescription = this.product.shortDescription;
      this.langEditables.longDescription = this.product.longDescription;
    },
    updateDefaultLangData() {
      this.product.name = this.langEditables.name;
      this.product.longDescription = this.langEditables.longDescription;
      this.product.shortDescription = this.langEditables.shortDescription;
    },
    setForNewLang() {
      this.langEditables.code = this.lang;
      this.langEditables.name = '';
      this.langEditables.shortDescription = '';
      this.langEditables.longDescription = '';
    },
    doesContainLang(currentLang) {
      return this.product.langs.some((item) => item.code === currentLang);
    },
    setNotDefaultLangToProduct(currentLang) {
      this.product.langs.forEach((item) => {
        if (item.code === currentLang) {
          item.name = this.langEditables.name;
          item.longDescription = this.langEditables.longDescription;
          item.shortDescription = this.langEditables.shortDescription;
        }
      });
    },
    async productEdited() {
      if (this.isDefaultLang(this.currentLang)) {
        this.updateDefaultLangData();
      } else {
        this.setNotDefaultLangToProduct(this.currentLang);
      }
      await this.updateProduct();
      this.productLangsEdited = false;
    },
    setSpecificationAndName() {
      this.pdfSpecification = this.getSpecificationByLang(this.product, this.lang, false);
      this.inputPdf.name = this.getFileName;
    },
    async onLangChange() {
      if (this.productLangsEdited) await this.productEdited();
      this.currentLang = this.lang;
      this.inputPdf = {};
      this.setSpecificationAndName();

      if (!this.isDefaultLang(this.lang)) {
        if (this.doesContainLang(this.lang)) {
          const currentLang = this.product.langs.find((item) => item.code === this.lang);
          this.langEditables = { ...currentLang };
        } else {
          this.setForNewLang();
        }
      } else {
        this.setDefaultLang();
      }
    },
    updateProductLanguages() {
      if (this.isDefaultLang(this.currentLang)) {
        this.product.name = this.langEditables.name;
        this.product.longDescription = this.langEditables.longDescription;
        this.product.shortDescription = this.langEditables.shortDescription;
      } else if (this.doesContainLang(this.currentLang)) {
        this.setNotDefaultLangToProduct(this.currentLang);
      } else {
        this.product.langs.push(this.langEditables);
      }
    },

    isAccessories() {
      return this.product.category === this.categoriesObj.MOUNTING_ACCESSORIES.value;
    },
    updateGroupQuantities(groupQuantities) {
      this.product.discountPricesQuanities = cloneDeep(groupQuantities);
    },
    async setImage() {
      if (this.inputImage.name) {
        try {
          const response = await ProductService.updateImage(this.productId, this.inputImage);
          this.product.image = response.data.image;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$toasted.global.error({
          message: VueI18n.t('components.singleProduct.toasted.setImageFunc'),
        });
      }
    },
    async deleteImage() {
      try {
        await ProductService.deleteImage(this.productId, this.inputImage);
        this.product.image = '';
        this.inputImage = {};
      } catch (error) {
        console.error(error);
      }
    },
    async setPdf() {
      if (this.inputPdf.name && this.inputPdf.size > 0) {
        try {
          await ProductService.updatePdf(this.productId, this.inputPdf, this.lang);
          this.$toasted.global.success({
            message: VueI18n.t('components.singleProduct.toasted.setPdfFunc', {
              fileName: this.inputPdf.name,
            }),
          });
          await this.getProduct(this.productId);
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$toasted.global.error({
          message: VueI18n.t('components.singleProduct.toasted.setImageFunc'),
        });
      }
    },
    async deletePdf() {
      try {
        await ProductService.deletePdf(this.productId, this.lang);
        this.$toasted.global.success({
          message: VueI18n.t('components.singleProduct.toasted.deletePdfFunc', {
            fileName: this.getFileName,
          }),
        });
        this.inputPdf = {};

        await this.getProduct(this.productId);
        this.pdfSpecification = this.getSpecificationByLang(this.product, this.lang);
      } catch (error) {
        console.error(error);
      }
    },
    formatFilePath(imagePath) {
      const [, fileName] = imagePath.split('@');
      return fileName;
    },
    async updateProduct() {
      this.isUpdating = true;
      if (!this.productFormValid) {
        this.isUpdating = false;
        return this.$toasted.global.error({
          message: VueI18n.t('components.singleProduct.toasted.updateProductFunc'),
        });
      }
      try {
        const producer = this.producers.find((prod) => prod.name === this.product.producer);
        if (producer) {
          this.product.producerId = producer._id;
        }
        this.product.visibility = this.visible;

        if (this.isAccessories()) {
          this.product.accessoryCategory = this.accessoryCategory;
        }

        this.updateProductLanguages();

        await ProductService.update(this.productId, this.product);
        await this.getProduct(this.productId);

        this.$toasted.global.success({
          message: `${VueI18n.t('components.singleProduct.toasted.isAccessoriesFunc')} ${
            this.langEditables.name
          }`,
        });

        this.productLangsEdited = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.isUpdating = false;
      }
    },
    isEmpty(val) {
      return isEmpty(val);
    },
    setAttributes() {
      const category = this.product.category;
      let cat = {};

      if (category === this.categoriesObj.CABEL_DC.value) {
        cat = new CableDC();
      } else if (category === this.categoriesObj.GROUNDED_CABLE.value) {
        cat = new GroundWire();
      } else if (category === this.categoriesObj.INVERTERS.value) {
        cat = new Inverter();
      } else if (category === this.categoriesObj.OPTIMIZERS.value) {
        cat = new Optimizers();
      } else if (category === this.categoriesObj.PHOTO_MODULES.value) {
        cat = new PhotovoltaicModules();
      } else if (category === this.categoriesObj.PRODUCTS_SET.value) {
        cat = new ProductsSet();
      } else if (category === this.categoriesObj.ENERGY_STORAGE.value) {
        this.product.subCategory = this.subCategoriesObj.CONTROLE_MODULE.value;
        cat = new EnergyStorageControleModule();
      } else if (category === this.categoriesObj.HEAT_PUMPS.value) {
        cat = new HeatPump();
      }

      this.product.attributes = cat.attributes;
    },
    setSubCategoryAttributes() {
      let cat = {};
      const subCategory = this.product.subCategory;

      if (subCategory === this.subCategoriesObj.CONTROLE_MODULE.value) {
        cat = new EnergyStorageControleModule();
      }

      if (subCategory === this.subCategoriesObj.BATTERY.value) {
        cat = new EnergyStorageBattery();
      }

      this.product.attributes = cat.attributes;
    },
    setDiscountPrices() {
      const { discountPrices } = this.product;
      this.product.discountPrices = discountPrices.length
        ? discountPrices
        : this.defaultDiscountPrices;
    },
    async getProduct(id) {
      try {
        const response = await ProductService.get(id);
        this.product = response.data;

        const producer = this.producers.find((prod) => prod._id === this.product.producerId);
        this.product.producer = producer?.name ? producer.name : '';
        this.accessoryCategory = this.product.accessoryCategory;

        this.visible = this.product.visibility;

        this.setDiscountPrices();
        this.setSpecificationAndName();
      } catch (error) {
        console.error(error);
      }
    },
    isTechnicalVisible() {
      const categoriesWithTechnicalAttributes = [
        this.categoriesObj.PHOTO_MODULES.value,
        this.categoriesObj.INVERTERS.value,
        this.categoriesObj.CABEL_DC.value,
        this.categoriesObj.GROUNDED_CABLE.value,
        this.categoriesObj.OPTIMIZERS.value,
        this.categoriesObj.PRODUCTS_SET.value,
        this.categoriesObj.ENERGY_STORAGE.value,
        this.categoriesObj.HEAT_PUMPS.value,
      ];

      if (
        this.product.category &&
        categoriesWithTechnicalAttributes.includes(this.product.category)
      ) {
        return true;
      } else {
        return false;
      }
    },
    async getProducers() {
      try {
        const response = await ProducerService.getAll();
        this.producers = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    setInitialValues() {
      this.product = {};
      this.inputPdf = {};
      this.inputImage = {};
      this.langEditables = {
        code: defaultLang.PL,
        name: '',
        shortDescription: '',
        longDescription: '',
      };
    },
  },
  async activated() {
    this.setInitialValues();
    const { productId } = this.$route.params;
    this.productId = productId;
    await this.getProducers();
    await this.getProduct(productId);
    this.getProducers();
    this.setDefaultLang();
  },
  deactivated() {
    this.setInitialValues();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../scss/_variables.scss';

.pointer {
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    color: $primary;
  }
}
.v-alert--outlined,
.theme--light.v-sheet--outlined {
  border: none !important;
}
</style>
