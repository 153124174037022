class PhotovoltaicModules {
  constructor(
    width,
    height,
    length,
    power,
    cableLength,
    numberCells,
    color,
    weight,
    fullblack,
    moduleThickness
  ) {
    this.attributes = [
      {
        name: 'Szerokość',
        value: width || '',
        unit: 'mm',
      },
      {
        name: 'Wysokość',
        value: height || '',
        unit: 'mm',
      },
      {
        name: 'Długość',
        value: length || '',
        unit: 'mm',
      },
      {
        name: 'Moc',
        value: power || '',
        unit: 'W',
      },
      {
        name: 'Długość kabla',
        value: cableLength || '',
        unit: 'mb',
      },
      {
        name: 'Liczba ogniw',
        value: numberCells || '',
        unit: '',
      },
      {
        name: 'Waga',
        value: weight || '',
        unit: 'kg',
      },
      {
        name: 'Kolor ramy',
        value: color || '',
        unit: '',
        options: ['Srebrny', 'Czarny'],
      },
      {
        name: 'Czy fullblack',
        value: fullblack || '',
        unit: '',
      },
      {
        name: 'Grubość modułu',
        value: moduleThickness || '',
        unit: 'mm',
        options: ['30', '32', '35', '38', '40', '42', '45', '50'],
      },
    ];
    this.categoryName = 'Panel Fotowoltaiczny';
  }
}

module.exports = PhotovoltaicModules;
