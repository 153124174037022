class HeatPump {
  constructor(power) {
    this.attributes = [
      {
        name: 'Moc',
        unit: 'kW',
        value: power || '',
      },
    ];
    this.categoryName = 'Pompa ciepła';
  }
}

module.exports = HeatPump;
