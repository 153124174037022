export const discountGroupsPath = Object.freeze({
  BASIC: {
    value: 'Grupa rabatowa podstawowa',
    path: 'BASIC',
  },
  STANDARD: {
    value: 'Grupa rabatowa standard',
    path: 'STANDARD',
  },
  PREMIUM: {
    value: 'Grupa rabatowa premium',
    path: 'PREMIUM',
  },
  VIP: {
    value: 'Grupa rabatowa VIP',
    path: 'VIP',
  },
});
