class GroundWire {
  constructor(length) {
    this.attributes = [
      {
        name: 'Długość',
        unit: 'mb',
        value: length || '',
      },
    ];

    this.categoryName = 'Przewód uziemiający';
  }
}

module.exports = GroundWire;
