<template>
  <v-dialog
    transition="dialog-bottom-transition"
    persistent
    v-model="open"
    max-width="650"
    scrollable
  >
    <v-card>
      <v-card-title class="text font-weight-bold"> {{ title }} </v-card-title>

      <v-container>
        <v-form ref="formModal">
          <v-row justify="center" class="ma-4 mt-0">
            <v-col>
              <v-text-field
                v-model="name"
                :label="$t('helpers.fileName')"
                prepend-icon="mdi-file-document"
                :counter="100"
                outlined
                :rules="validator.requiredRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-4 mt-0">
            <v-col>
              <v-file-input
                v-model="file"
                :label="$t('helpers.file')"
                prepend-icon="mdi-file"
                :rules="validator.fileRulesWithExtensions"
                show-size
                outlined
              ></v-file-input>
            </v-col>

            <v-col>
              <v-select
                v-model="lang"
                :items="langs"
                :label="$t('helpers.language')"
                prepend-icon="mdi-translate"
                outlined
                :rules="validator.requiredRules"
                item-text="text"
                item-value="value"
              ></v-select
            ></v-col>
          </v-row>
          <v-row justify="center" class="ma-4 mt-0">
            <v-btn
              elevation="0"
              block
              @click="accept"
              :loading="loading"
              :color="color ? color : 'primary'"
              class="pa-4 v-size--large white--text"
              x-large
              >{{ $t('helpers.accept') }}</v-btn
            >
          </v-row>
          <v-row justify="center">
            <v-btn class="mb-4 font-weight-medium border-bottom" color="font" text @click="decline">
              {{ $t('helpers.cancel') }}</v-btn
            >
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { supportedLanguages } from '../../plugins/i18n';
import { validators } from '../../validators/Validators';

export default {
  name: 'AddProductFileModal',
  props: ['open', 'title', 'color', 'loading'],
  data() {
    return {
      name: '',
      file: null,
      lang: '',
    };
  },
  computed: {
    langs() {
      return supportedLanguages;
    },
    validator() {
      return validators;
    },
  },
  methods: {
    decline() {
      this.$emit('decline');
    },
    accept() {
      if (!this.$refs.formModal.validate()) return;

      this.$emit('accept', { name: this.name, file: this.file, lang: this.lang });
      this.resetState();
      this.$refs.formModal.reset();
    },
    resetState() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
</script>
<style lang="scss" scoped>
.border-bottom:after {
  content: '\00a0';
  position: absolute;
  transform: translateY(600%);
  width: 55px;
  height: 2px;
  background: #333333;
}
</style>
