<template>
  <div class="border">
    <v-row class="px-4 py-0 my-0 align-center">
      <v-col cols="12" sm="3">
        <h3 class="mt-1" data-test="name">{{ priceList.name }}</h3>
      </v-col>
      <v-col cols="12" sm="3">
        {{ $t('components.singleProduct.standardPrice') }}
        <v-text-field
          min="0"
          type="number"
          data-test="price"
          v-model.number="priceList.price"
          solo
          hide-details="auto"
          ref="priceInput"
          @change="updatePrice"
        />
      </v-col>
    </v-row>
    <div class="mt-4">
      <discount-prices
        v-if="priceList.discountPricesQuanities"
        :discountPrices="priceList.discountPrices"
        :discountPricesQuanities="priceList.discountPricesQuanities"
        @updateGroupQuantities="updateGroupQuantities"
      ></discount-prices>
    </div>
    <v-col cols="12" sm="3" class="tertiary-text--text align-self-end ml-auto">
      <v-btn
        data-test="delete-button"
        elevation="0"
        block
        @click="deletePriceList"
        :color="'secondary'"
        class="pa-4 v-size--large white--text larger"
        large
        >{{ $t('components.priceLists.deletePriceList') }}</v-btn
      >
    </v-col>
  </div>
</template>

<script>
import DiscountPrices from '../DiscountPrices.vue';

export default {
  name: 'PriceListItem',
  emits: ['priceListDeleted', 'updatePrice', 'updatePriceListGroupQuantities'],
  components: { DiscountPrices },
  props: {
    priceList: {
      type: Object,
      default: () => {
        return { _id: null, name: '', countries: [], price: 0.0 };
      },
    },
  },
  methods: {
    updatePrice() {
      this.$emit('updatePrice', this.priceList.priceListId, this.priceList.price);
    },
    async deletePriceList() {
      this.$emit('priceListDeleted', this.priceList.priceListId);
    },
    updateGroupQuantities(groupQuantities) {
      this.$emit('updatePriceListGroupQuantities', this.priceList.priceListId, groupQuantities);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-btn:not(.v-btn--round).v-size--large {
  height: 48px;
}
.border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 1rem;
}
</style>
