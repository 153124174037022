class EnergyStorageControleModule {
  constructor(supportedQuantity) {
    this.attributes = [
      {
        name: 'supportedAmount',
        unit: 'szt',
        value: supportedQuantity || '',
      },
    ];
    this.categoryName = 'Moduł kontrolny';
  }
}

module.exports = EnergyStorageControleModule;
