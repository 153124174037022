import { selectedLanguageComputed } from '../store/helper';
import defaultLang from '../static/defaultLang';
import config from '../../configuration.json';

export const getNameByLanguage = {
  computed: {
    ...selectedLanguageComputed,
  },
  methods: {
    supportSelectedLang(product, language = this.selectedLanguage) {
      return (
        language !== defaultLang.PL && product.langs.some((product) => product.code === language)
      );
    },
    hasSpecification(product, languageToCompare) {
      return product.langs.find((lang) => lang.code === languageToCompare)?.pdfSpecification;
    },
    productTextOptions(product) {
      return product.langs.find((product) => product.code === this.selectedLanguage);
    },
    getNameByLang(product) {
      if (this.supportSelectedLang(product)) {
        const name = this.productTextOptions(product)?.name;
        return name && this.noSpacesRule(name) ? name : product.name;
      }

      return product.name;
    },
    getShortDescriptionByLang(product) {
      if (this.supportSelectedLang(product)) {
        const shortDescription = this.productTextOptions(product)?.shortDescription;
        return shortDescription && this.noSpacesRule(shortDescription)
          ? shortDescription
          : product.shortDescription;
      } else return product.shortDescription;
    },
    getLongDescriptionByLang(product) {
      if (this.supportSelectedLang(product)) {
        const longDescription = this.productTextOptions(product)?.longDescription;
        return longDescription && this.noSpacesRule(longDescription)
          ? longDescription
          : product.longDescription;
      } else return product.longDescription;
    },
    noSpacesRule(value) {
      return value.replace(/\s/g, '').length > 0;
    },
    getSpecificationByLang(product, lang = this.selectedLanguage, displayToUser = false) {
      if (lang === defaultLang.PL) {
        return this.fixSpecUrl(product.pdfSpecification);
      } else {
        if (this.supportSelectedLang(product, lang) && this.hasSpecification(product, lang)) {
          return this.fixSpecUrl(this.hasSpecification(product, lang));
        } else if (displayToUser) {
          return this.fixSpecUrl(product.pdfSpecification);
        } else return '';
      }
    },
    fixSpecUrl(urlToFix) {
      return `${config.VUE_APP_BACKEND_URL}/uploads/${urlToFix.split('uploads/')[1]}`;
    },
  },
};
